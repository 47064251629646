import BulkEditMachineModal from './modals/BulkEditMachineModal.jsx'
import EditMachineModal from './modals/EditMachineModal.jsx'
import AddMachineModal from './modals/AddMachineModal.jsx'
import fetchProducts from '../../endpoint-requests/products/fetchProducts.js'
import getTimeZoneNames from '../../endpoint-requests/machine-configuration/getTimeZoneNames.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { useMachine } from '@xstate/react'
import React, { useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { assign, Machine } from 'xstate'
import DeleteModal from '../../components-soapy/ConfirmationModal.jsx'
import FullPagePaper from '../../components-soapy/FullPagePaper'
import Snackbar from '../../components/Snackbar/Snackbar.jsx'
import fetchDisconnectionLogs from '../../endpoint-requests/machine-configuration/fetchDisconnectionLogs'
import { getMachines } from '../../endpoint-requests/machine-configuration/getMachines'
import fetchMachineSettings from '../../endpoint-requests/machine-update-settings/fetchMachineSettings.js'
import deleteMachine from '../../endpoint-requests/machine/deleteMachine'
import { deleteUuid } from '../../endpoint-requests/machine/deleteUuid'
import DisconnectionLogsTableModal from './modals/DisconnectionLogsTableModal.jsx'
import TriggerSoapModalContainer from './modals/TriggerSoapModalContainer'
import UnitConfigurationsTable from './UnitConfigurationsTable'
import UnitConfigurationsTableHeader from './UnitConfigurationsTableHeader.jsx'
import _ from 'lodash'
import PurgeModal from './modals/PurgeModal'

const unitsMachine = Machine({
  id: 'units',
  initial: 'loadUnitData',
  context: {
    machines: {},
    selected: {},
    disconnectionLogs: [],
    productNameAndVersions: [],
    timeZoneNames: [],
    isLoadingDisconnectionLogs: false,
    error: '',
    machine: {},
    isSuccessNotificationVisible: false,
    updateParametersTime: 0
  },
  states: {
    loadUnitData: {
      invoke: {
        src: (context, event) => Promise.all([getMachines(), fetchMachineSettings(), fetchProducts(), getTimeZoneNames()]),
        onDone: {
          target: 'main',
          actions: assign({
            machines: (context, event) => event.data[0],
            updateParametersTime: (context, event) => event.data[1].updateParametersTime,
            productNameAndVersions: (context, event) => {
              return event.data[2].map((product) => {
                return product.name + '-' + product.version
              })
            },
            timeZoneNames: (context, event) => event.data[3]
          })
        },
        onError: {
          target: 'failedFetchingData',
          actions: assign({
            error: (context, event) => event.data.message
          })
        }
      }
    },
    failedFetchingData: {
      type: 'final'
    },
    deleteMachineModal: {
      on: {
        CLOSE_DELETE_MACHINE_MODAL: {
          target: 'main',
          actions: ['setMachine']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        },
        MACHINE_DELETED: {
          actions: ['deleteMachine']
        }
      }
    },
    purgeModal: {
      on: {
        CLOSE_PURGE_MODAL: {
          target: 'editMachineModal',
          // actions: ['setMachine']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        },
        PURGE_MODAL_DONE: {
          actions: ['setMachine']
        },
      }
    },
    deleteUuidModal: {
      on: {
        CLOSE_UUID_DELETE_MODAL: {
          target: 'main',
          actions: ['setMachine']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        },
        UUID_DELETED: {
          target: 'main',
          actions: ['deleteMachineUUID']
        }
      }
    },
    triggerSoapModal: {
      on: {
        CLOSE_TRIGGER_SOAP_MODAL: 'main',
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    disconnectionLogsModal: {
      on: {
        CLOSE_DISCONNECTION_LOGS_MODAL: 'main'
      },
      entry: ['showDisconnectionLogsLoading'],
      invoke: {
        src: (context, event) => fetchDisconnectionLogs(context.machine.id),
        onDone: {
          actions: assign({
            disconnectionLogs: (context, event) => event.data,
            isLoadingDisconnectionLogs: (context, event) => false
          })
        },
        onError: {
          target: 'failedFetchingData',
          actions: assign({
            error: (context, event) => event.data.message
          })
        }
      }
    },
    main: {
      exit: ['hideSuccessNotification'],
      after: { 3500: { actions: ['hideSuccessNotification'], cond: 'isSuccessNotificationVisible' } },
      on: {
        OPEN_ADD_MACHINE_MODAL: {
          target: 'addMachineModal',
          actions: ['setMachine']
        },
        OPEN_EDIT_MACHINE_MODAL: {
          target: 'editMachineModal',
          actions: ['setMachine']
        },
        OPEN_BULK_EDIT_MACHINE_MODAL: {
          target: 'bulkEditMachineModal',
          actions: ['setMachine']
        },
        OPEN_DELETE_MACHINE_MODAL: {
          target: 'deleteMachineModal',
          actions: ['setMachine']
        },
        OPEN_DISCONNECTION_LOGS_MODAL: {
          target: 'disconnectionLogsModal',
          actions: ['setMachine']
        },
        OPEN_UUID_DELETE_MODAL: {
          target: 'deleteUuidModal',
          actions: ['setMachine']
        },
        OPEN_TRIGGER_SOAP_MODAL: {
          target: 'triggerSoapModal',
          actions: ['setMachine']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        },
        HIDE_SUCCESS_NOTIFICATION: {
          actions: ['hideSuccessNotification']
        },
        CLOSE_DELETE_MODAL: 'main',
        CLOSE_UUID_DELETE_MODAL: 'main',
        CLOSE_TRIGGER_SOAP_MODAL: 'main',
        UPDATE_SELECTED_MACHINES: {
          target: 'main',
          actions: assign({
            selected: (context, event) => event.selected
          })
        }
      }
    },
    addMachineModal: {
      on: {
        CLOSE_ADD_MACHINE_MODAL: 'main',
        MACHINES_ADDED: {
          target: 'main',
          actions: 'addMachines'
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    editMachineModal: {
      on: {
        CLOSE_EDIT_MACHINE_MODAL: {
          target: 'main',
          actions: ['setMachine']
        },
        MACHINE_EDITED: {
          target: 'main',
          actions: ['editMachine']
        },
        OPEN_PURGE_MODAL: {
          target: 'purgeModal',
          // actions: ['setMachine']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    },
    bulkEditMachineModal: {
      on: {
        CLOSE_BULK_EDIT_MACHINE_MODAL: 'main',
        MACHINES_EDITED: {
          target: 'main',
          actions: ['editMachines', 'setSelected']
        },
        SHOW_SUCCESS_NOTIFICATION: {
          actions: ['showSuccessNotification']
        }
      }
    }
  }
},
  {
    actions: {
      showDisconnectionLogsLoading: assign({
        isLoadingDisconnectionLogs: true
      }),
      showSuccessNotification: assign({
        isSuccessNotificationVisible: true
      }),
      hideSuccessNotification: assign({
        isSuccessNotificationVisible: false
      }),
      deleteMachine: assign({
        machines: (context, event) => context.machines.filter(machine => machine.id !== event.machine.id)
      }),
      editMachine: assign({
        machines: (context, event) => context.machines.map(machine => machine.id === event.machine.id ? event.machine : machine)
      }),
      editMachines: assign({
        machines: (context, event) => context.machines.map(machine => {
          const eventMachine = event.machines.find(eventMachine => eventMachine.id === machine.id)
          return eventMachine || machine
        })
      }),
      setSelected: assign({
        selected: (context, event) => event.selected
      }),
      addMachines: assign({
        machines: (context, event) => [...event.machines, ...context.machines]
      }),
      deleteMachineUUID: assign({
        machines: (context, event) => context.machines.map(machine => machine.id === event.machine.id ? _.omit(machine, ['uuid']) : machine)
      }),
      setMachine: assign({
        machine: (context, event) => event.machine
      })
    },
    guards: {
      isSuccessNotificationVisible: (context, event) => {
        return !!context.isSuccessNotificationVisible
      }
    }

  }
)

const UnitConfigurations = ({ t }) => {
  const [current, send] = useMachine(unitsMachine)
  const [openViewIcons, setOpenViewIcons] = useState(false)
  const [openActionIcons, setOpenActionIcons] = useState(false)

  const tableRef = useRef()
  switch (current.value) {
    case 'loadUnitData':
      return (
        <CircularProgress
          color='primary'
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        />
      )
    case 'failedFetchingData':
      return <div>{t(current.context.error)}</div>
    default: {
      const { machines, productNameAndVersions, timeZoneNames, machine, isSuccessNotificationVisible, isLoadingDisconnectionLogs, disconnectionLogs, updateParametersTime, selected } = current.context
      const isAddMachineModalOpen = current.value === 'addMachineModal'
      const isEditMachineModalOpen = (current.value === 'editMachineModal' || current.value === 'purgeModal')
      const isBulkEditMachineModalOpen = current.value === 'bulkEditMachineModal'
      const isDeleteMachineModalOpen = current.value === 'deleteMachineModal'
      const isPurgeModalOpen = current.value === 'purgeModal'
      const isUuidDeleteModalOpen = current.value === 'deleteUuidModal'
      const isTriggerSoapModalOpen = current.value === 'triggerSoapModal'
      const isDisconnectionLogsModalOpen = current.value === 'disconnectionLogsModal'
      return (
        <FullPagePaper openViewIcons={openViewIcons} setOpenViewIcons={setOpenViewIcons} openActionIcons={openActionIcons} setOpenActionIcons={setOpenActionIcons}>
          <Snackbar
            place='tc'
            color='success'
            icon={NotificationsIcon}
            message={t('Saved') + '!'}
            open={isSuccessNotificationVisible}
          />
          <BulkEditMachineModal
            isOpen={isBulkEditMachineModalOpen}
            productNameAndVersions={productNameAndVersions}
            timeZoneNames={timeZoneNames}
            onClose={() => {
              send('CLOSE_BULK_EDIT_MACHINE_MODAL')
            }}
            selected={selected}
            machinesEdited={(machines) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'MACHINES_EDITED', machines, selected: {} }])
            }}
          />
          <EditMachineModal
            openPurgeModal={(machine) => {
              send({ type: 'OPEN_PURGE_MODAL', machine })
            }}
            isOpen={isEditMachineModalOpen}
            machine={machine}
            productNameAndVersions={productNameAndVersions}
            timeZoneNames={timeZoneNames}
            onClose={() => {
              send({ type: 'CLOSE_EDIT_MACHINE_MODAL', machine: {} })
            }}
            machineEdited={(machine) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'MACHINE_EDITED', machine }])
            }}
          />
          <AddMachineModal
            isOpen={isAddMachineModalOpen}
            productNameAndVersions={productNameAndVersions}
            timeZoneNames={timeZoneNames}
            onClose={() => {
              send({ type: 'CLOSE_ADD_MACHINE_MODAL', machine: {} })
            }}
            machinesAdded={(machines) => {
              send(['SHOW_SUCCESS_NOTIFICATION', { type: 'MACHINES_ADDED', machines }])
            }}
          />
          <DisconnectionLogsTableModal
            isOpen={isDisconnectionLogsModalOpen}
            disconnectionLogs={disconnectionLogs}
            isLoading={isLoadingDisconnectionLogs}
            onCancel={() => {
              send('CLOSE_DISCONNECTION_LOGS_MODAL')
            }}
            title={t('Disconnection Log')}
          />
          <DeleteModal
            isOpen={isDeleteMachineModalOpen}
            onClose={() => {
              send({ type: 'CLOSE_DELETE_MACHINE_MODAL', machine: {} })
            }}
            onApply={async () => {
              await deleteMachine(machine.id)
              send([{
                type: 'MACHINE_DELETED',
                machine
              }, 'SHOW_SUCCESS_NOTIFICATION', { type: 'CLOSE_DELETE_MACHINE_MODAL', machine: {} }])
            }}
          />
          <PurgeModal
            isOpen={isPurgeModalOpen}
            machine={machine}
            onClose={() => {
              send('CLOSE_PURGE_MODAL')
            }}
            onApply={async (machine) => {
              send([{
                type: 'PURGE_MODAL_DONE', machine
              },
                'CLOSE_PURGE_MODAL'
              ])
            }}
          />
          <DeleteModal
            title={t('Delete UUID')}
            isOpen={isUuidDeleteModalOpen}
            onClose={() => {
              send({ type: 'CLOSE_UUID_DELETE_MODAL', machine: {} })
            }}
            onApply={async () => {
              await deleteUuid(machine.id)
              send([{ type: 'UUID_DELETED', machine }, 'SHOW_SUCCESS_NOTIFICATION', {
                type: 'CLOSE_UUID_DELETE_MODAL',
                machine: {}
              }])
            }}
          />
          <TriggerSoapModalContainer
            isOpen={isTriggerSoapModalOpen}
            onClose={(isDeleteClicked = false) => {
              if (isDeleteClicked) {
                send(['SHOW_SUCCESS_NOTIFICATION', 'CLOSE_TRIGGER_SOAP_MODAL'])
              } else {
                send('CLOSE_TRIGGER_SOAP_MODAL')
              }
            }}
            machine={machine}
          />
          <UnitConfigurationsTableHeader
            openEditMachineModal={() => {
              send({ type: 'OPEN_ADD_MACHINE_MODAL', machine: {} })
            }}
            openBulkEditMachineModal={() => {
              send({ type: 'OPEN_BULK_EDIT_MACHINE_MODAL', machine: {} })
            }}
            selected={selected}
            machinesExist={!!machines.length}
            tableRef={tableRef}
          />
          <UnitConfigurationsTable
            openViewIcons={openViewIcons}
            setOpenViewIcons={setOpenViewIcons}
            openActionIcons={openActionIcons}
            setOpenActionIcons={setOpenActionIcons}
            machines={machines}
            unit={machine}
            tableRef={tableRef}
            selected={selected}
            updateParametersTime={updateParametersTime}
            closeDisconnectionLogsModal={() => {
              send('CLOSE_DISCONNECTION_LOGS_MODAL')
            }}
            openDisconnectionLogsModal={(machine) => {
              send({ type: 'OPEN_DISCONNECTION_LOGS_MODAL', machine })
            }}
            openDeleteModal={(machine) => {
              send({ type: 'OPEN_DELETE_MACHINE_MODAL', machine })
            }}
            setSelected={(selected) => {
              send('UPDATE_SELECTED_MACHINES', {
                selected
              })
            }}
            openUuidDeleteModal={(machine) => {
              send({ type: 'OPEN_UUID_DELETE_MODAL', machine })
            }}
            openTriggerSoapModal={(machine) => {
              send({ type: 'OPEN_TRIGGER_SOAP_MODAL', machine })
            }}
            openEditMachineModal={(machine) => {
              send({ type: 'OPEN_EDIT_MACHINE_MODAL', machine })
            }}
            isSuccessNotificationVisible={isSuccessNotificationVisible}
          />
        </FullPagePaper>
      )
    }
  }
}

export default withTranslation()(UnitConfigurations)
