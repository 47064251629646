export const TYPE_VALUES = ['ECO']
export const PRODUCT_NAME_VERSION_VALUES = ['ECO-01', 'ECO-02']
export const PRODUCT_NAME_VERSION = 'productNameVersion'
export const VERSION = 'productName'
export const TYPE = 'type'
export const REAGENT = 'reagent'
export const DB_TIME_ZONE = 'dbTimeZone'
export const INTERNAL_AREA = 'internalArea'
export const INSTALLATION_DATE = 'installationDate'
export const LATITUDE = 'latitude'
export const LONGITUDE = 'longitude'
export const WATER_USE = 'waterUse'
export const WATER_WASTE = 'waterWaste'
export const SOAP_USE = 'soapUse'
export const SOAP_WASTE = 'soapWaste'
export const SERIAL_NUMBER = 'serialNumber'
export const SOAP_TIME = 'soapTime'
export const WATER_TIME_DURING_SOAP = 'waterTimeDuringSoap'
export const LATHER_TIME = 'latherTime'
export const WATER_CYCLE = 'waterCycle'
export const WATER_TIME_PER_WATER_CYCLE = 'waterTimePerWaterCycle'
export const DELAY_TIME_PER_WATER_CYCLE = 'delayTimePerWaterCycle'
export const DEBUG_MODE = 'debugMode'
export const SOAP_LOADING_TIME = 'soapLoadingTime'
export const ENABLE_VISUAL_ERROR = 'enableVisualError'
export const NUMBER_OF_UNIT = 'numberOfUnit'
export const SHOW_MULTIPLE_UNIT_INPUT = 'showMultipleUnitInput'
export const AFTER_WASH_DELAY = 'afterWashDelay'
export const PASSWORD = 'password'
export const ADDITIONAL_WATER_TIME = 'additionalWaterTime'
export const TEMPERATURE_SUPPORT = 'temperatureSupport'
export const TEMPERATURE_OFFSET = 'temperatureOffset'
export const MONITOR = 'monitor'
export const REAGENT_BOTTLE_ID = 'reagentBottleId'
export const LED_PROGRESS_BAR = 'ledProgressBar'
export const REAGENT_NAME = 'reagentName'
export const RECOGNITION = 'recognition'
export const HANDS_RECOGNITION = 'handsRecognition'
export const HANDS_RECOGNITION_DATA = 'handsRecognitionData'
export const DEBUG_HANDS_RECOGNITION = 'debugHandsRecognition'
export const ATP_MODE = 'atpMode'
export const TIME_BETWEEN_SOAP_AND_WATER = 'timeBetweenSoapAndWater'
export const AUTOMATIC_SOAP_PURGE = 'automaticSoapPurge'
export const MONITOR_ORIENTATION = 'monitorOrientation'
export const PUBLIC_PLACES_SUPPORT = 'publicPlacesSupport'
export const PURGE_MINUTES_AFTER_LAST_WASH = 'purgeMinutesAfterLastWash'
export const PURGE_PRIME_REAGENT_SECONDS = 'primeReagentSeconds'
export const PURGE_REPEAT_TIMES = 'purgeRepeatTimes'
export const WASH_START_SOAP_WATER = 'washStartSoapWater'
export const STOP_CYCLE_SUPPORT = 'stopCycleSupport'
export const ATP_FINISH_DATE = 'atpFinishDate'
